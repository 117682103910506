import "react-tabs/style/react-tabs.css"
import "react-accessible-accordion/dist/fancy-example.css"

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"
import {
  ArrowDown,
  ArrowRight,
  ArrowUp,
  BoxArrowInUpRight,
  QuestionCircleFill,
} from "@styled-icons/bootstrap"
import React, { useEffect, useState } from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"

import Image from "gatsby-image"
import { Link } from "gatsby"
import Modal from "react-modal"
import Seo from "../../components/seo"
import { XLg } from "@styled-icons/bootstrap"
import classNames from "classnames"
import { graphql } from "gatsby"
import sanitizeHtml from "sanitize-html"
import slugify from "slugify"
import { useInView } from "react-intersection-observer"
import { useWindowSize } from "@uidotdev/usehooks"

export default function PaediatricApproach({
  data: {
    wpInteractiveModel,
    wp: { themeOptions },
  },
}) {
  const [modalContent, setModalContent] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [seenModel, setSeenModel] = useState()
  const [clickedAcknowledgement, setClickedAcknowledgement] = useState(false)
  const [readArticles, setReadArticles] = useState([])

  const size = useWindowSize()

  const blueMap = [
    "bg-sky-200",
    "bg-sky-300",
    "bg-sky-400",
    "bg-sky-500",
    "bg-sky-600",
    "bg-sky-700",
    "bg-sky-800",
    "bg-sky-900",
  ]

  const fontBlueMap = [
    "text-sky-200",
    "text-sky-300",
    "text-sky-400",
    "text-sky-500",
    "text-sky-600",
    "text-sky-700",
    "text-sky-800",
    "text-sky-900",
  ]

  const layoutMap = {
    "sensory-systems":
      "basis-auto md:basis-2/6 [&:nth-last-child(-n+4)]:md:basis-3/12",
    "motor-skills": "basis-auto md:basis-2/6",
    "emotional-regulation":
      "basis-auto md:[&:first-of-type]:basis-4/6 [&:not(first-of-type)]:md:basis-3/6",
    "daily-living-activities":
      "basis-auto md:basis-3/6 [&:last-of-type]:md:basis-full",
    "executive-functioning": "",
  }

  const { ref, inView } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  })

  useEffect(() => {
    if (localStorage.getItem("paeds_model_seen")) {
      setSeenModel(true)
    }
  }, [])

  useEffect(() => {
    const data = window.localStorage.getItem("whr_read_articles")
    if (data !== null) setReadArticles(JSON.parse(data))
  }, [])

  useEffect(() => {
    Modal.setAppElement(document.getElementById("___gatsby"))
  })

  useEffect(() => {
    window.localStorage.setItem(
      "whr_read_articles",
      JSON.stringify(readArticles)
    )
  }, [readArticles])

  const updateReadArticles = e => {
    if (e.target.checked) {
      if (!readArticles.includes(e.target.value)) {
        setReadArticles([...readArticles, e.target.value])
      }
      return
    }
    if (readArticles.includes(e.target.value)) {
      setReadArticles(
        readArticles.filter(readArticle => readArticle !== e.target.value)
      )
    }
  }

  const openTutorialMenu = () => {
    localStorage.setItem("paeds_model_seen", Date.now())
    setSeenModel(true)
    setModalContent({
      description: sanitizeHtml(
        wpInteractiveModel?.paediatricApproachFields?.tutorial
      ),
      title: "About",
      interventions: [],
      assessments: [],
    })
    setModalOpen(true)
  }

  const openLayerModal = ({ layerTitle, layerDescription, layerTabs }) => {
    setModalContent({
      description: sanitizeHtml(layerDescription),
      title: layerTitle,
      interventions: [],
      assessments: [],
      layerTabs,
    })
    setModalOpen(true)
  }

  const hideAcknowledgement = () => {
    setClickedAcknowledgement(true)
  }

  const findRelatedLayer = layerTitle => {
    let relatedField = null
    wpInteractiveModel?.paediatricApproachFields?.layers?.forEach(
      ({ layer }) => {
        const layerItems = layer?.layerItems?.concat([
          {
            layerItem: {
              itemDescription: layer?.layerDescription,
              itemTitle: layer?.layerTitle,
              itemAssessments: null,
              itemInterventions: null,
              itemResources: null,
              itemRelated: null,
              layerTabs: layer?.layerTabs,
            },
          },
        ])

        // layer?.layerItems?.forEach(({ layerItem }) => {

        layerItems?.forEach(({ layerItem }) => {
          if (layerItem.itemTitle === layerTitle) {
            relatedField = {
              description: layerItem?.itemDescription,
              title: layerItem?.itemTitle,
              assessments: layerItem?.itemAssessments,
              interventions: layerItem?.itemInterventions,
              resources: layerItem?.itemResources,
              related: layerItem?.itemRelated,
              layerTabs: layerItem?.layerTabs,
            }
          }
        })
      }
    )
    return relatedField
  }

  const AccordionWrapper = ({ items }) => {
    return (
      <Accordion className="border-none">
        {items?.map(({ item }, index) => {
          return (
            <AccordionItem key={index} className="mt-2">
              <AccordionItemHeading>
                <AccordionItemButton
                  className={classNames(
                    "bg-transparent font-bold border-white border-b flex justify-between items-center pb-1",
                    item.description
                      ? "after:content-['+'] aria-expanded:after:content-['—']"
                      : "cursor-default"
                  )}
                >
                  {item?.title}
                </AccordionItemButton>
              </AccordionItemHeading>
              {item?.description && (
                <AccordionItemPanel className="px-0 py-6 animate-slide-in">
                  <div
                    className="prose-p:mb-6"
                    dangerouslySetInnerHTML={{
                      __html: item?.description,
                    }}
                  ></div>
                </AccordionItemPanel>
              )}
            </AccordionItem>
          )
        })}
      </Accordion>
    )
  }

  const TabPanelInner = ({ referrer, items, intro }) => {
    return (
      <div className="border border-white p-6">
        {intro && (
          <div
            className="prose-p:mb-6"
            dangerouslySetInnerHTML={{
              __html: intro,
            }}
          ></div>
        )}
        {(referrer === "interventions" || referrer === "assessments") && (
          <AccordionWrapper items={items} />
        )}
        {referrer === "resources" &&
          items?.map(({ item }, index) => {
            return (
              <a
                key={index}
                className="flex w-max mb-1 font-bold items-center hover:text-whr-orange hover:transition-colors duration-300"
                href={item?.resource?.url}
                target={item?.resource?.target === "_blank" ? "_blank" : ""}
                rel={item?.resource?.target === "_blank" ? "noreferrer" : ""}
              >
                {item?.resource?.title}
                {item?.resource?.target ? (
                  <BoxArrowInUpRight className="h-4 ml-1" />
                ) : (
                  <ArrowRight className="h-4 ml-1" />
                )}
              </a>
            )
          })}
        {referrer === "related" &&
          items?.map(({ item }, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  const relatedField = findRelatedLayer(item?.itemRelatedTitle)
                  if (relatedField) {
                    setModalOpen(false)
                    setModalContent(relatedField)
                    setTimeout(() => {
                      setModalOpen(true)
                    }, 450)
                  }
                }}
                className="flex w-max mb-1 font-bold items-center hover:text-whr-orange hover:transition-colors duration-300"
              >
                {item?.itemRelatedTitle}
              </button>
            )
          })}
        {referrer === "layerTabs" &&
          items?.map(({ item }, index) => {
            return item?.contentStyle === "wysiwyg" ? (
              <div
                key={index}
                className="prose-p:mb-6"
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              ></div>
            ) : (
              <AccordionWrapper key={index} items={item?.accordion} />
            )
          })}
      </div>
    )
  }

  return (
    <main className="bg-gradient-to-b from-gray-900 to-gray-600 bg-gradient-to-r pt-12">
      <Seo
        description="The WHR Layers of Learning has been developed to support parents, caregivers, service providers and educators in understanding how our nervous system develops and how each stage contributes to developing skills for everyday living."
        title="Layers of Learning | WHR Allied Health"
      />
      {wpInteractiveModel?.paediatricApproachFields?.acknowledgement && (
        <div
          className={classNames(
            "h-screen overflow-hidden transition-all duration-1000 px-12 md:px-16",
            {
              "h-0": clickedAcknowledgement,
            }
          )}
        >
          <div
            className={classNames(
              "h-full transition-opacity duration-500 flex flex-col items-center justify-center prose-p:text-white",
              {
                "opacity-0": clickedAcknowledgement,
              }
            )}
          >
            <div
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(
                  wpInteractiveModel?.paediatricApproachFields?.acknowledgement
                ),
              }}
            ></div>
            <button
              onClick={hideAcknowledgement}
              className="border border-full rounded-sm px-6 py-2 mt-12 bg-white text-gray-900 animate-pulse"
            >
              Continue
            </button>
          </div>
        </div>
      )}

      <div>
        <div className="flex flex-wrap gap-6 justify-between items-start mb-12 lg:mb-24 px-6 md:pl-20 md:pr-12">
          <Link to="/" aria-label="Click this logo to return home">
            <Image
              fluid={
                themeOptions?.themeOptions?.themeLogo?.localFile
                  ?.childImageSharp?.fluid
              }
              alt="The WHR Allied Health logo"
              className="[&_img]:!static [&_img]:!object-contain [&_img]:!h-10 [&_img]:md:!h-12 [&>img]:!absolute [&>div]:!pb-0 [&_img]:!object-left"
            />
          </Link>
          <div className="flex flex-col w-full md:w-max md:max-w-[45%] mt-6 md:mt-0">
            {wpInteractiveModel?.paediatricApproachFields?.introduction && (
              <div
                className="text-white prose-headings:text-white prose-headings:text-3xl prose-headings:mb-4 text-left md:text-right"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    wpInteractiveModel?.paediatricApproachFields?.introduction
                  ),
                }}
              ></div>
            )}
            {wpInteractiveModel?.paediatricApproachFields?.tutorial && (
              <button
                onClick={openTutorialMenu}
                className={classNames("mt-4 w-max md:ml-auto", {
                  "animate-pulse": !seenModel,
                })}
              >
                <QuestionCircleFill className="h-8 w-8 fill-whr-orange hover:fill-white transition-colors duration-300" />
              </button>
            )}
          </div>
        </div>

        <div
          className={classNames(
            "relative flex before:h-0.5 before:bg-white before:w-full before:absolute before:scale-0",
            {
              "before:scale-100 before:transition-transform before:duration-1000 before:ease-in before:delay-300": inView,
            }
          )}
          ref={ref}
        >
          <div
            className={classNames(
              "top-0 sticky h-screen hidden md:flex justify-center items-center text-white text-sm lg:text-2xl px-3 md:px-6 after:absolute after:h-full after:scale-0 after:w-0.5 after:bg-white after:right-0",
              {
                "after:scale-100 after:transition-all after:duration-1000 after:ease-in": inView,
              }
            )}
            style={{ writingMode: "vertical-rl" }}
          >
            <ArrowUp className="h-6 md:h-8 mb-6" />
            <span>Development Level</span>
            <ArrowDown className="h-6 md:h-8 mt-6" />
          </div>
          <div className="pt-12 xl:py-24 flex-grow flex justify-center">
            <div className="flex flex-col">
              {wpInteractiveModel?.paediatricApproachFields?.layers?.map(
                ({ layer }, layerIndex) => {
                  return (
                    <div
                      key={`${layer?.layerTitle}`}
                      className={classNames(
                        "py-10 lg:py-16 px-6 lg:px-32 flex flex-wrap justify-center items-center mx-auto text-white opacity-0 transition-opacity duration-1000 rounded-t-lg flex-grow",
                        blueMap[layerIndex],
                        {
                          "!opacity-100": inView,
                        }
                      )}
                      style={{
                        width: `${
                          100 -
                          (wpInteractiveModel?.paediatricApproachFields?.layers
                            .length -
                            layerIndex) *
                            (size.width < 768 ? 8 : 13)
                        }%`,
                        transitionDelay: `${(layerIndex + 1) * 100}ms`,
                      }}
                    >
                      <button
                        onClick={() => {
                          layer?.layerDescription && openLayerModal(layer)
                        }}
                        className={classNames(
                          "text-[1.5rem] md:text-[1.75rem] lg:text-[2.5rem] text-white text-center font-['Bebas_Neue']",
                          layer?.layerDescription
                            ? "after:h-px after:w-full after:bg-white after:mt-0.5 after:scale-0 after:block hover:after:scale-100 after:transition-transform after:duration-300"
                            : "cursor-default",
                          {
                            "!text-whr-orange after:bg-whr-orange": readArticles.includes(
                              slugify(layer?.layerTitle, {
                                lower: true,
                              })
                            ),
                          }
                        )}
                      >
                        {layer?.layerTitle}
                      </button>
                      {layer?.layerItems && (
                        <div
                          data-layer={slugify(layer?.layerTitle, {
                            lower: true,
                          })}
                          className="flex flex-wrap justify-center w-full mt-6 lg:mt-8"
                        >
                          {layer?.layerItems?.map(({ layerItem }, index) => {
                            return (
                              <div
                                key={index}
                                className={classNames(
                                  "flex justify-center px-2",
                                  layoutMap[
                                    slugify(layer?.layerTitle, {
                                      lower: true,
                                    })
                                  ]
                                )}
                              >
                                <button
                                  onClick={() => {
                                    setModalContent({
                                      description: layerItem?.itemDescription,
                                      title: layerItem?.itemTitle,
                                      interventions:
                                        layerItem?.itemInterventions,
                                      assessmentsIntro:
                                        layerItem?.assessmentsIntroduction,
                                      interventionsIntro:
                                        layerItem?.interventionsIntroduction,
                                      assessments: layerItem?.itemAssessments,
                                      resources: layerItem?.itemResources,
                                      related: layerItem?.itemRelated,
                                    })
                                    setModalOpen(true)
                                  }}
                                  key={`${index}-${layerItem?.itemTitle}`}
                                  className={classNames(
                                    "text-md relative after:absolute after:-bottom-0 after:left-0 duration-300 mb-3 bg-white py-2 px-6 flex flex-grow justify-center items-center font-bold rounded-md shadow-lg hover:opacity-75 hover:transition-opacity hover:duration-300",
                                    fontBlueMap[layerIndex],
                                    {
                                      "!bg-whr-orange !text-white": readArticles.includes(
                                        slugify(layerItem?.itemTitle, {
                                          lower: true,
                                        })
                                      ),
                                    }
                                  )}
                                >
                                  {layerItem?.itemTitle}
                                </button>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  )
                }
              )}
            </div>
          </div>
          <Modal isOpen={modalOpen} className="bg-gray-900 shadow-2xl">
            <div className="flex gap-12 justify-between items-start mb-6">
              <h2 className="text-[2.25rem] text-white">
                {modalContent?.title}
              </h2>
              <button
                aria-label="Close modal"
                className=""
                onClick={() => setModalOpen(false)}
              >
                <XLg className="h-6 w-6" />
              </button>
            </div>
            {modalContent && (
              <>
                <div
                  className="mb-8 prose-p:mb-6 prose-li:mb-0 prose-ul:mb-6"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(modalContent?.description),
                  }}
                />
                {(modalContent?.interventions?.length !== 0 ||
                  modalContent?.assessments?.length !== 0 ||
                  modalContent?.resources?.length !== 0 ||
                  modalContent?.related?.length !== 0 ||
                  modalContent?.layerTabs.length !== 0) && (
                  <Tabs>
                    <TabList className="!p-0 flex overflow-x-auto">
                      {(modalContent?.assessments?.length > 0 ||
                        modalContent?.assessmentsIntro) && (
                        <Tab className="group w-max before:hidden py-2 px-4 border-l border-r border-t border-white aria-selected:border-white rounded-tl-lg rounded-tr-lg cursor-pointer mb-0">
                          <h2 className="text-white group-aria-selected:text-gray-900 text-2xl">
                            Assessments
                          </h2>
                        </Tab>
                      )}
                      {(modalContent?.interventions?.length > 0 ||
                        modalContent?.interventionsIntro) && (
                        <Tab className="group w-max before:hidden py-2 px-4 border-l border-r border-t border-white aria-selected:border-white rounded-tl-lg rounded-tr-lg cursor-pointer mb-0">
                          <h2 className="text-white group-aria-selected:text-gray-900 text-2xl">
                            Interventions
                          </h2>
                        </Tab>
                      )}
                      {modalContent?.resources?.length > 0 && (
                        <Tab className="group w-max before:hidden py-2 px-4 border-l border-r border-t border-white aria-selected:border-white rounded-tl-lg rounded-tr-lg cursor-pointer mb-0">
                          <h2 className="text-white group-aria-selected:text-gray-900 text-2xl">
                            Resources
                          </h2>
                        </Tab>
                      )}
                      {modalContent?.related?.length > 0 && (
                        <Tab className="group w-max before:hidden py-2 px-4 border-l border-r border-t border-white aria-selected:border-white rounded-tl-lg rounded-tr-lg cursor-pointer mb-0">
                          <h2 className="text-white group-aria-selected:text-gray-900 text-2xl">
                            Related
                          </h2>
                        </Tab>
                      )}
                      {modalContent?.layerTabs?.length > 0 &&
                        modalContent?.layerTabs?.map(({ layerTab }, index) => {
                          return (
                            <Tab
                              key={slugify(layerTab?.title)}
                              className="group w-max before:hidden py-2 px-4 border-l border-r border-t border-white aria-selected:border-white rounded-tl-lg rounded-tr-lg cursor-pointer mb-0"
                            >
                              <h2 className="text-white group-aria-selected:text-gray-900 text-2xl">
                                {layerTab?.title}
                              </h2>
                            </Tab>
                          )
                        })}
                    </TabList>
                    {(modalContent?.assessments?.length > 0 ||
                      modalContent?.assessmentsIntro) && (
                      <TabPanel>
                        <TabPanelInner
                          referrer="assessments"
                          items={modalContent.assessments}
                          intro={modalContent?.assessmentsIntro}
                        />
                      </TabPanel>
                    )}
                    {(modalContent?.interventions?.length > 0 ||
                      modalContent?.interventionsIntro) && (
                      <TabPanel>
                        <TabPanelInner
                          referrer="interventions"
                          items={modalContent.interventions}
                          intro={modalContent?.interventionsIntro}
                        />
                      </TabPanel>
                    )}
                    {modalContent?.resources?.length > 0 && (
                      <TabPanel>
                        <TabPanelInner
                          referrer="resources"
                          items={modalContent.resources}
                        />
                      </TabPanel>
                    )}
                    {modalContent?.related?.length > 0 && (
                      <TabPanel>
                        <TabPanelInner
                          referrer="related"
                          items={modalContent.related}
                        />
                      </TabPanel>
                    )}
                    {modalContent?.layerTabs?.length > 0 &&
                      modalContent?.layerTabs?.map(({ layerTab }) => {
                        return (
                          <TabPanel key={slugify(layerTab?.title)}>
                            <TabPanelInner
                              referrer="layerTabs"
                              items={[
                                {
                                  item: {
                                    accordion: layerTab?.accordion,
                                    contentStyle: layerTab?.contentStyle,
                                    description: layerTab?.description,
                                    title: layerTab?.title,
                                  },
                                },
                              ]}
                            />
                          </TabPanel>
                        )
                      })}
                  </Tabs>
                )}
              </>
            )}
            <div className="flex mt-12">
              <input
                id={`read-${slugify(modalContent.title || "", {
                  lower: true,
                })}`}
                className="absolute h-0 w-0 opacity-0 peer"
                onChange={e => {
                  updateReadArticles(e)
                }}
                value={slugify(modalContent.title || "", {
                  lower: true,
                })}
                type="checkbox"
                checked={readArticles.includes(
                  slugify(modalContent.title || "", {
                    lower: true,
                  })
                )}
              />
              <label
                htmlFor={`read-${slugify(modalContent.title || "", {
                  lower: true,
                })}`}
                className="ml-auto flex items-center cursor-pointer before:flex before:h-5 before:w-5 before:border before:border-whr-orange before:mr-2 peer-checked:before:bg-whr-orange"
              >
                I've read this section.
              </label>
            </div>
          </Modal>
        </div>
      </div>
    </main>
  )
}

export const pageQuery = graphql`
  query PaediatricApproachQuery {
    wpInteractiveModel(slug: { eq: "paediatric-approach" }) {
      title
      paediatricApproachFields {
        acknowledgement
        introduction
        tutorial
        layers {
          layer {
            layerDescription
            layerTitle
            layerItems {
              layerItem {
                assessmentsIntroduction
                interventionsIntroduction
                itemAssessments {
                  item {
                    description
                    title
                  }
                }
                itemDescription
                itemInterventions {
                  item {
                    description
                    title
                  }
                }
                itemResources {
                  item {
                    resource {
                      target
                      title
                      url
                    }
                  }
                }
                itemRelated {
                  item {
                    itemRelatedTitle
                  }
                }
                itemTitle
              }
            }
            layerTabs {
              layerTab {
                description
                title
                contentStyle
                accordion {
                  item {
                    description
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        themeOptions {
          themeLogo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
